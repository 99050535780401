/* External Imports */
import { useDispatch, useSelector } from "react-redux";

/* Internal Imports */
import "bootstrap/dist/css/bootstrap.min.css";
import { Button, Col, Container, Form, Row } from "react-bootstrap";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper-bundle.css";
import delivery from "../../assets/imgs/template/delivery.svg";
import support from "../../assets/imgs/template/support.svg";
import voucher from "../../assets/imgs/template/voucher.svg";
import returned from "../../assets/imgs/template/return.svg";
import secure from "../../assets/imgs/template/secure.svg";
import living from "../../assets/imgs/page/homepage6/living-room.png";
import reading from "../../assets/imgs/page/homepage6/reading-room.png";
import kitchen from "../../assets/imgs/page/homepage6/kitchen.png";
import decoration from "../../assets/imgs/page/homepage6/decoration.png";
import bedroom from "../../assets/imgs/page/homepage6/bed-room.png";
import brand1 from "../../assets/imgs/1.png";
import brand2 from "../../assets/imgs/2.png";
import brand3 from "../../assets/imgs/3.png";
import brand4 from "../../assets/imgs/4.png";
import brand5 from "../../assets/imgs/5.png";

import sp1 from "../../assets/imgs/page/homepage6/sp1.png";
import sp2 from "../../assets/imgs/page/homepage6/sp2.png";
import sp3 from "../../assets/imgs/page/homepage6/sp3.png";
import sp4 from "../../assets/imgs/page/homepage6/sp4.png";
import sp5 from "../../assets/imgs/page/homepage6/sp5.png";
import sp6 from "../../assets/imgs/page/homepage6/sp6.png";
import sp7 from "../../assets/imgs/page/homepage6/sp7.png";
import sp8 from "../../assets/imgs/page/homepage6/sp8.png";
import sp9 from "../../assets/imgs/page/homepage6/sp9.png";
import sp10 from "../../assets/imgs/page/homepage6/sp10.png";
import star from "../../assets/imgs/template/icons/star.svg";
import banner1 from "../../assets/imgs/ads/finolex.jpg";
import banner2 from "../../assets/imgs/ads/GM.jpg";
import news1 from "../../assets/imgs/page/homepage6/news1.png";
import news2 from "../../assets/imgs/page/homepage6/news2.png";
import news3 from "../../assets/imgs/page/homepage6/news3.png";
import news4 from "../../assets/imgs/page/homepage6/news4.png";
import Header from "../Header";
import Footer from "../Footer";
import Sidebar from "../Sidebar";
import { useEffect } from "react";
import { BrandDataGetRequest, productGetRequest } from "../../store/products/products.action";
import { productSelector } from "../../store/products/products.model";
import { API_BASE } from "../../services/config";
import noImg from "../../assets/imgs/no-image.jpg";
import ImageSlider from "../Slider/ImageSlider";

function Home() {
  const dispatch = useDispatch();
  const { getProducts, getBrandList } = useSelector(productSelector);
  /* State */

  /* Function */

  /* UseEffect */
  useEffect(() => {
    const payload = {
      page: 0,
      limit: 10,
      query: "",
      cat_id: "",
      brand_id: "",
      sortBy: "created_at",
        order: "asc",
    };
    dispatch(productGetRequest(payload));
  }, []);
  useEffect(()=>{
    dispatch(BrandDataGetRequest());

  },[])
  return (
    <>
      <Header />
      <Sidebar />
      <section className="section-box mt-35">
        <Container>
          <Row>
            <Col lg={3}>
              <div className="banner-left">
                <div className="box-adsolute-banner">
                  <h5 className="color-gray-900">Fan Collection</h5>
                  <a className="btn btn-link-brand-2 text-capitalize">
                    Shop Now
                  </a>
                </div>
              </div>
            </Col>
            {/* <Col lg={6} className="mb-30">
              <Swiper>
                <div className="swiper-container swiper-banner-1">
                  <div className="swiper-wrapper">
                    <SwiperSlide>
                      <div className="banner-middle image1">
                        <div className="box-adsolute-banner">
                          <h6 className="color-gray-900 mb-10">Big & Fresh Offers</h6>
                          <h2 className="color-gray-1000 mb-5">DECORA & CRAFTS</h2>
                          <h6 className="sale-tag">Sale On This Weekend</h6>
                        </div>
                      </div>
                    </SwiperSlide>
                    <SwiperSlide>
                      <div className="banner-middle image2">
                        <div className="box-adsolute-banner">
                          <h6 className="color-gray-900 mb-10">Big & Fresh Offers</h6>
                          <h2 className="color-gray-1000 mb-5">DECORA & CRAFTS</h2>
                          <h6 className="sale-tag">Sale On This Weekend</h6>
                        </div>
                      </div>
                    </SwiperSlide>
                  </div>
                  <div className="swiper-button-next swiper-button-next-style-4"></div>
                  <div className="swiper-button-prev swiper-button-prev-style-4"></div>
                </div>
              </Swiper>
            </Col> */}
            <Col lg={3}>
              <div className="banner-right-home6">
                <div className="box-adsolute-banner">
                  <h5 className="color-gray-900">Switch and Sockets</h5>
                  <a className="btn btn-link-brand-2 text-capitalize">
                    Shop Now
                  </a>
                </div>
              </div>
            </Col>
            <Col lg={3}>
              <div className="banner-third">
                <div className="box-adsolute-banner">
                  <h5 className="color-gray-900">Pipes Collection</h5>
                  <a className="btn btn-link-brand-2 text-capitalize">
                    Shop Now
                  </a>
                </div>
              </div>
            </Col>
            <Col lg={3}>
              <div className="banner-fourth">
                <div className="box-adsolute-banner">
                  <h5 className="color-gray-900">Wires</h5>
                  <a className="btn btn-link-brand-2 text-capitalize">
                    Shop Now
                  </a>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
      <div className="section-box mt-0 mb-25">
        <Container>
          <ul className="list-col-5">
            <li>
              <div className="item-list">
                <div className="icon-left">
                  <img src={delivery} alt="Ecom" />
                </div>
                <div className="info-right">
                  <h5 className="font-lg-bold color-gray-100">Free Delivery</h5>
                  <p className="font-sm color-gray-500">From all orders</p>
                </div>
              </div>
            </li>
            <li>
              <div className="item-list">
                <div className="icon-left">
                  <img src={support} alt="Ecom" />
                </div>
                <div className="info-right">
                  <h5 className="font-lg-bold color-gray-100">Support 24/7</h5>
                  <p className="font-sm color-gray-500">Shop with an expert</p>
                </div>
              </div>
            </li>
            <li>
              <div className="item-list">
                <div className="icon-left">
                  <img src={voucher} alt="Ecom" />
                </div>
                <div className="info-right">
                  <h5 className="font-lg-bold color-gray-100">Gift voucher</h5>
                  <p className="font-sm color-gray-500">Refer a friend</p>
                </div>
              </div>
            </li>
            <li>
              <div className="item-list">
                <div className="icon-left">
                  <img src={returned} alt="Ecom" />
                </div>
                <div className="info-right">
                  <h5 className="font-lg-bold color-gray-100">
                    Return &amp; Refund
                  </h5>
                  <p className="font-sm color-gray-500">Free return </p>
                </div>
              </div>
            </li>
            <li>
              <div className="item-list">
                <div className="icon-left">
                  <img src={secure} alt="Ecom" />
                </div>
                <div className="info-right">
                  <h5 className="font-lg-bold color-gray-100">
                    Secure payment
                  </h5>
                  <p className="font-sm color-gray-500">100% Protected</p>
                </div>
              </div>
            </li>
          </ul>
        </Container>
      </div>
      <section className="section-box mt-20">
        <Container>
          <Row>
            <Col lg={12}>
              <h3>Most Popular Brands</h3>
            </Col>
          </Row>
          <div className="mt-50">
            <Swiper
              spaceBetween={50}
              slidesPerView={5}
              breakpoints={{
                576: {
                  // width: 576,
                  slidesPerView: 1,
                },
                768: {
                  // width: 768,
                  slidesPerView: 5,
                },
              }}
              onSlideChange={() => console.log("slide change")}
              onSwiper={(swiper) => console.log(swiper)}
            >
              <div className="swiper-container swiper-group-5">
                <div className="swiper-wrapper pt-5">
                  {getBrandList?.map((brand,index)=>{
                    return index <5 &&(
                      <SwiperSlide>
                    <div className="card-category">
                      <div className="card-image">
                        <a href="#">
                          <img src={brand1} alt="Ecom" />
                        </a>
                      </div>
                      <div className="card-info">
                        <a href="blog-single.html">
                          <h5 className="color-brand-3 mb-5">{brand?.name}</h5>
                          {/* <p className="font-md color-gray-500">{brand?.description}</p> */}
                        </a>
                      </div>
                    </div>
                  </SwiperSlide>
                    )
                  })}
                  {/* <SwiperSlide>
                    <div className="card-category">
                      <div className="card-image">
                        <a href="#">
                          <img src={brand1} alt="Ecom" />
                        </a>
                      </div>
                      <div className="card-info">
                        <a href="blog-single.html">
                          <h5 className="color-brand-3 mb-5">Finolex</h5>
                          <p className="font-md color-gray-500">156 products</p>
                        </a>
                      </div>
                    </div>
                  </SwiperSlide>
                  <SwiperSlide>
                    <div className="card-category">
                      <div className="card-image">
                        <a href="#">
                          <img src={brand2} alt="Ecom" />
                        </a>
                      </div>
                      <div className="card-info">
                        <a href="blog-single.html">
                          <h5 className="color-brand-3 mb-5">Havels</h5>
                          <p className="font-md color-gray-500">156 products</p>
                        </a>
                      </div>
                    </div>
                  </SwiperSlide>
                  <SwiperSlide>
                    <div className="card-category">
                      <div className="card-image">
                        <a href="#">
                          <img src={brand3} alt="Ecom" />
                        </a>
                      </div>
                      <div className="card-info">
                        <a href="blog-single.html">
                          <h5 className="color-brand-3 mb-5">Fybros</h5>
                          <p className="font-md color-gray-500">156 products</p>
                        </a>
                      </div>
                    </div>
                  </SwiperSlide>
                  <SwiperSlide>
                    <div className="card-category">
                      <div className="card-image">
                        <a href="#">
                          <img src={brand4} alt="Ecom" />
                        </a>
                      </div>
                      <div className="card-info">
                        <a href="blog-single.html">
                          <h5 className="color-brand-3 mb-5">Legrand</h5>
                          <p className="font-md color-gray-500">156 products</p>
                        </a>
                      </div>
                    </div>
                  </SwiperSlide>
                  <SwiperSlide>
                    <div className="card-category">
                      <div className="card-image">
                        <a href="#">
                          <img src={brand5} alt="Ecom" />
                        </a>
                      </div>
                      <div className="card-info">
                        <a href="blog-single.html">
                          <h5 className="color-brand-3 mb-5">Anchor</h5>
                          <p className="font-md color-gray-500">78 products</p>
                        </a>
                      </div>
                    </div>
                  </SwiperSlide>
                  <SwiperSlide>
                    <div className="card-category">
                      <div className="card-image">
                        <a href="#">
                          <img src={decoration} alt="Ecom" />
                        </a>
                      </div>
                      <div className="card-info">
                        <a href="blog-single.html">
                          <h5 className="color-brand-3 mb-5">Decorations</h5>
                          <p className="font-md color-gray-500">93 products</p>
                        </a>
                      </div>
                    </div>
                  </SwiperSlide>
                  <SwiperSlide>
                    <div className="card-category">
                      <div className="card-image">
                        <a href="#">
                          <img src={decoration} alt="Ecom" />
                        </a>
                      </div>
                      <div className="card-info">
                        <a href="blog-single.html">
                          <h5 className="color-brand-3 mb-5">Decorations</h5>
                          <p className="font-md color-gray-500">43 products</p>
                        </a>
                      </div>
                    </div>
                  </SwiperSlide>
                  <SwiperSlide>
                    <div className="card-category">
                      <div className="card-image">
                        <a href="#">
                          <img src={decoration} alt="Ecom" />
                        </a>
                      </div>
                      <div className="card-info">
                        <a href="blog-single.html">
                          <h5 className="color-brand-3 mb-5">Decorations</h5>
                          <p className="font-md color-gray-500">112 products</p>
                        </a>
                      </div>
                    </div>
                  </SwiperSlide> */}
                </div>
              </div>
            </Swiper>
          </div>
          <div className="border-bottom"></div>
        </Container>
      </section>
      <section className="section-box mt-50">
        <Container>
          <div className="head-main bd-gray-200">
            <Row>
              <Col xl={6} lg={6}>
                <h3 className="mb-5">Trending Products</h3>
                <p className="font-base color-gray-500">
                  Shop Electronics, Crafted by Innovators All Over the World
                </p>
              </Col>
              <Col xl={6} lg={6}>
                <ul
                  className="nav nav-tabs text-uppercase pr-100"
                  role="tablist"
                >
                  <li>
                    <a
                      className="active"
                      href="#tab-3-all"
                      data-bs-toggle="tab"
                      role="tab"
                      aria-controls="tab-3-all"
                      aria-selected="true"
                      data-index="1"
                    >
                      All
                    </a>
                  </li>
                  <li>
                    <a
                      href="#tab-3-bestseller"
                      data-bs-toggle="tab"
                      role="tab"
                      aria-controls="tab-3-bestseller"
                      aria-selected="false"
                      data-index="2"
                    >
                      Best seller
                    </a>
                  </li>
                  <li>
                    <a
                      href="#tab-3-mostviewed"
                      data-bs-toggle="tab"
                      role="tab"
                      aria-controls="tab-3-mostviewed"
                      aria-selected="false"
                      data-index="3"
                    >
                      Most viewed
                    </a>
                  </li>
                  <li>
                    <a
                      href="#tab-3-topbrands"
                      data-bs-toggle="tab"
                      role="tab"
                      aria-controls="tab-3-topbrands"
                      aria-selected="false"
                      data-index="4"
                    >
                      Top Brands
                    </a>
                  </li>
                </ul>
                <div className="box-button-slider">
                  <div className="button-slider-nav" id="tab-3-all-nav">
                    <div className="swiper-button-next swiper-button-next-tab-1"></div>
                    <div className="swiper-button-prev swiper-button-prev-tab-1"></div>
                  </div>
                  <div className="button-slider-nav" id="tab-3-bestseller-nav">
                    <div className="swiper-button-next swiper-button-next-tab-2"></div>
                    <div className="swiper-button-prev swiper-button-prev-tab-2"></div>
                  </div>
                  <div className="button-slider-nav" id="tab-3-mostviewed-nav">
                    <div className="swiper-button-next swiper-button-next-tab-3"></div>
                    <div className="swiper-button-prev swiper-button-prev-tab-3"></div>
                  </div>
                  <div className="button-slider-nav" id="tab-3-topbrands-nav">
                    <div className="swiper-button-next swiper-button-next-tab-4"></div>
                    <div className="swiper-button-prev swiper-button-prev-tab-4"></div>
                  </div>
                </div>
              </Col>
            </Row>
          </div>
          <div className="tab-content tab-content-slider">
            <div
              className="tab-pane fade active show"
              id="tab-3-all"
              role="tabpanel"
              aria-labelledby="tab-3-all"
            >
              <Swiper>
                <div className="swiper-container swiper-tab-1">
                  <div className="swiper-wrapper pt-5">
                    <SwiperSlide>
                      <div className="list-products-5">
                        {getProducts?.data?.map((ele,index) => {
                          return (
                            <div className="card-grid-style-3 home6-style">
                              <div className="card-grid-inner">
                                <div className="image-box">
                                  {ele?.w_rate != ele?.msrp && (
                                    <span className="label bg-brand-2">
                                      {(
                                        100 -
                                        (ele?.w_rate / ele.msrp) * 100
                                      ).toFixed(0)}
                                      %
                                    </span>
                                  )}
                                  <a href="#">
                                    {/* <img
                                      src={ ele?.image
                                        ? API_BASE + "products/" + ele?.image:noImg}
                                      alt="Ecom"
                                    /> */}
                                    <ImageSlider images={ele.image} id={index} />
                                  </a>
                                </div>
                                <div className="info-right">
                                  <a
                                    className="font-xs color-gray-500"
                                    href="#"
                                  >
                                    {ele?.name}
                                  </a>
                                  <br />
                                  <a
                                    className="color-brand-3 font-sm-bold"
                                    href="#"
                                  >
                                    {ele?.description}
                                  </a>
                                  {localStorage.getItem("isAuthenticated") && 
                                    <div className="price-info mb-10">
                                    <strong className="font-lg-bold color-brand-3 price-main">
                                      {ele?.w_rate}
                                    </strong>
                                    {ele?.w_rate != ele?.msrp && (
                                      <span className="color-gray-500 price-line">
                                        ₹ {ele?.msrp}
                                      </span>
                                    )}
                                  </div>
                                  }
                                  
                                  {/* <div className="mt-10 box-btn-cart">
                                    <a
                                      className="btn btn-cart"
                                      href="shop-cart.html"
                                    >
                                      Add To Cart
                                    </a>
                                  </div> */}
                                </div>
                              </div>
                            </div>
                          );
                        })}
                      </div>
                    </SwiperSlide>
                  </div>
                </div>
              </Swiper>
            </div>
            <div
              className="tab-pane fade"
              id="tab-3-bestseller"
              role="tabpanel"
              aria-labelledby="tab-3-bestseller"
            >
              <Swiper>
                <div className="swiper-container swiper-tab-1">
                  <div className="swiper-wrapper pt-5">
                    <SwiperSlide>
                      <div className="list-products-5">
                        {getProducts?.data?.map((ele,idx) => {
                          return (
                            
                            <div className="card-grid-style-3 home6-style">
                              <div className="card-grid-inner">
                                <div className="image-box">
                                  {ele?.sale_price != ele?.reg_price && (
                                    <span className="label bg-brand-2">
                                      {(
                                        100 -
                                        (ele?.sale_price / ele.reg_price) * 100
                                      ).toFixed(0)}
                                      %
                                    </span>
                                  )}
                                  <a href="#">
                                    {/* <img
                                      src={ ele?.image
                                        ? API_BASE + "products/" + ele?.image:noImg}
                                      alt="Ecom"
                                    /> */}
                                    <ImageSlider images={ele.image} id={"100qw"+idx} />
                                  </a>
                                </div>
                                <div className="info-right">
                                  <a
                                    className="font-xs color-gray-500"
                                    href="#"
                                  >
                                    {ele?.name}
                                  </a>
                                  <br />
                                  <a
                                    className="color-brand-3 font-sm-bold"
                                    href="#"
                                  >
                                    {ele?.description}
                                  </a>
                                  <div className="price-info mb-10">
                                    <strong className="font-lg-bold color-brand-3 price-main">
                                      {ele?.sale_price}
                                    </strong>
                                    {ele?.sale_price != ele?.reg_price && (
                                      <span className="color-gray-500 price-line">
                                        ₹ {ele?.reg_price}
                                      </span>
                                    )}
                                  </div>
                                  {/* <div className="mt-10 box-btn-cart">
                                    <a
                                      className="btn btn-cart"
                                      href="shop-cart.html"
                                    >
                                      Add To Cart
                                    </a>
                                  </div> */}
                                </div>
                              </div>
                            </div>
                          );
                        })}
                      </div>
                    </SwiperSlide>
                  </div>
                </div>
              </Swiper>
            </div>
            <div
              className="tab-pane fade"
              id="tab-3-mostviewed"
              role="tabpanel"
              aria-labelledby="tab-3-mostviewed"
            >
              <Swiper>
                <div className="swiper-container swiper-tab-1">
                  <div className="swiper-wrapper pt-5">
                    <SwiperSlide>
                      <div className="list-products-5">
                        {getProducts?.data?.map((ele,index) => {
                          return (
                            <div className="card-grid-style-3 home6-style">
                              <div className="card-grid-inner">
                                <div className="image-box">
                                  {ele?.sale_price != ele?.reg_price && (
                                    <span className="label bg-brand-2">
                                      {(
                                        100 -
                                        (ele?.sale_price / ele.reg_price) * 100
                                      ).toFixed(0)}
                                      %
                                    </span>
                                  )}
                                  <a href="#">
                                    {/* <img
                                      src={ ele?.image
                                        ? API_BASE + "products/" + ele?.image:noImg}
                                      alt="Ecom"
                                    /> */}
                                    <ImageSlider images={ele.image} id={"200qw"+index} />
                                  </a>
                                </div>
                                <div className="info-right">
                                  <a
                                    className="font-xs color-gray-500"
                                    href="#"
                                  >
                                    {ele?.name}
                                  </a>
                                  <br />
                                  <a
                                    className="color-brand-3 font-sm-bold"
                                    href="#"
                                  >
                                    {ele?.description}
                                  </a>
                                  <div className="price-info mb-10">
                                    <strong className="font-lg-bold color-brand-3 price-main">
                                      {ele?.sale_price}
                                    </strong>
                                    {ele?.sale_price != ele?.reg_price && (
                                      <span className="color-gray-500 price-line">
                                        ₹ {ele?.reg_price}
                                      </span>
                                    )}
                                  </div>
                                  {/* <div className="mt-10 box-btn-cart">
                                    <a
                                      className="btn btn-cart"
                                      href="shop-cart.html"
                                    >
                                      Add To Cart
                                    </a>
                                  </div> */}
                                </div>
                              </div>
                            </div>
                          );
                        })}
                      </div>
                    </SwiperSlide>
                  </div>
                </div>
              </Swiper>
            </div>
            <div
              className="tab-pane fade"
              id="tab-3-topbrands"
              role="tabpanel"
              aria-labelledby="tab-3-topbrands"
            >
              <Swiper>
                <div className="swiper-container swiper-tab-1">
                  <div className="swiper-wrapper pt-5">
                    <SwiperSlide>
                      <div className="list-products-5">
                        {getProducts?.data?.map((ele,index) => {
                          return (
                            <div className="card-grid-style-3 home6-style">
                              <div className="card-grid-inner">
                                <div className="image-box">
                                  {ele?.sale_price != ele?.reg_price && (
                                    <span className="label bg-brand-2">
                                      {(
                                        100 -
                                        (ele?.sale_price / ele.reg_price) * 100
                                      ).toFixed(0)}
                                      %
                                    </span>
                                  )}
                                  <a href="#">
                                    {/* <img
                                      src={ ele?.image
                                        ? API_BASE + "products/" + ele?.image:noImg}
                                      alt="Ecom"
                                    /> */}
                                    <ImageSlider images={ele.image} id={"tt"+index} />
                                  </a>
                                </div>
                                <div className="info-right">
                                  <a
                                    className="font-xs color-gray-500"
                                    href="#"
                                  >
                                    {ele?.name}
                                  </a>
                                  <br />
                                  <a
                                    className="color-brand-3 font-sm-bold"
                                    href="#"
                                  >
                                    {ele?.description}
                                  </a>
                                  <div className="price-info mb-10">
                                    <strong className="font-lg-bold color-brand-3 price-main">
                                      {ele?.sale_price}
                                    </strong>
                                    {ele?.sale_price != ele?.reg_price && (
                                      <span className="color-gray-500 price-line">
                                        ₹ {ele?.reg_price}
                                      </span>
                                    )}
                                  </div>
                                  {/* <div className="mt-10 box-btn-cart">
                                    <a
                                      className="btn btn-cart"
                                      href="shop-cart.html"
                                    >
                                      Add To Cart
                                    </a>
                                  </div> */}
                                </div>
                              </div>
                            </div>
                          );
                        })}
                      </div>
                    </SwiperSlide>
                  </div>
                </div>
              </Swiper>
            </div>
          </div>
        </Container>
      </section>
      <section className="section-box mt-50">
        <Container>
          <Row>
            <Col lg={6} className="mb-30">
              <div className="card-banner hover-up">
                <div className="card-image">
                  <a href="#">
                    {" "}
                    <img src={banner1} alt="Ecom" />
                  </a>
                </div>
                <div className="card-info mt-10 text-center">
                  {" "}
                  <a href="#">
                    <h4 className="color-gray-900">
                      Electricals sets from ₹9.99
                    </h4>
                  </a>
                  <p className="font-md color-gray-900">Upto 30% offer</p>
                </div>
              </div>
            </Col>
            <Col lg={6} className="mb-30">
              <div className="card-banner hover-up">
                <div className="card-image">
                  {" "}
                  <a href="#">
                    <img src={banner2} alt="Ecom" />
                  </a>
                </div>
                <div className="card-info mt-10 text-center">
                  {" "}
                  <a href="#">
                    <h4 className="color-gray-900">Hardware sets from ₹9.99</h4>
                  </a>
                  <p className="font-md color-gray-900">Upto 30% offer</p>
                </div>
              </div>
            </Col>
          </Row>
          <div className="border-bottom"></div>
        </Container>
      </section>

      <section className="section-box mt-50">
        <Container>
          <Row>
            <Col lg={4}>
              <Row>
                <Col lg={12} md={6} sm={6}>
                  <div className="banner-bottom-1 bbt_box">
                    {" "}
                    <a href="#">
                      <div className="banner-bottom-info top-style-1">
                        <h4 className="color-white mb-5">Reading Table</h4>
                        <p className="font-base-2 color-white">Under ₹50</p>
                      </div>
                    </a>
                  </div>
                </Col>
                <Col lg={12} md={6} sm={6}>
                  <div className="banner-bottom-2 bbt_box">
                    {" "}
                    <a href="#">
                      <div className="banner-bottom-info top-style-1">
                        <h4 className="color-white mb-5">
                          Table Lamp Collection
                        </h4>
                        <p className="font-base-2 color-white">
                          Aqua Shift Special Edition
                        </p>
                      </div>
                    </a>
                  </div>
                </Col>
              </Row>
            </Col>
            <Col lg={4}>
              <div className="banner-bottom-3 bbt_box">
                <div className="banner-bottom-info top-style-2 ">
                  <h4 className="color-white mb-5">Top Rated Chair</h4>
                  <p className="font-base-2 color-white mb-20">Under ₹120</p>
                  <a className="btn btn-info btn-arrow-right">Shop Now</a>
                </div>
              </div>
            </Col>
            <Col lg={4}>
              <Row>
                <Col lg={12} md={6} sm={6}>
                  <div className="banner-bottom-4 bbt_box">
                    {" "}
                    <a href="#">
                      <div className="banner-bottom-info top-style-1">
                        <h4 className="color-white mb-5">Sofa Collection</h4>
                        <p className="font-base-2 color-white">
                          Aqua Shift Special Edition
                        </p>
                      </div>
                    </a>
                  </div>
                </Col>
                <Col lg={12} md={6} sm={6}>
                  <div className="banner-bottom-5 bbt_box">
                    {" "}
                    <a href="#">
                      <div className="banner-bottom-info top-style-1">
                        <h4 className="color-white mb-5">Relaxing Chair</h4>
                        <p className="font-base-2 color-white">
                          From ₹100. Free shipping
                        </p>
                      </div>
                    </a>
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      </section>
      <section className="section-box mt-40">
        <Container>
          <div className="banner-ads-3">
            <h5 className="mb-5 color-gray-900">70% off on limited switches</h5>
            <p className="font-base color-gray-900 mb-10">
              Free shipping available for purchases more than ₹3000.
            </p>
            <a className="btn btn-brand-3">View Products</a>
          </div>
        </Container>
      </section>

      <section className="section-box mt-90 mb-50">
        <Container>
          <ul className="list-col-5">
            <li>
              <div className="item-list">
                <div className="icon-left">
                  <img src={delivery} alt="Ecom" />
                </div>
                <div className="info-right">
                  <h5 className="font-lg-bold color-gray-100">Free Delivery</h5>
                  <p className="font-sm color-gray-500">
                    From all orders over ₹10
                  </p>
                </div>
              </div>
            </li>
            <li>
              <div className="item-list">
                <div className="icon-left">
                  <img src={support} alt="Ecom" />
                </div>
                <div className="info-right">
                  <h5 className="font-lg-bold color-gray-100">Support 24/7</h5>
                  <p className="font-sm color-gray-500">Shop with an expert</p>
                </div>
              </div>
            </li>
            <li>
              <div className="item-list">
                <div className="icon-left">
                  <img src={voucher} alt="Ecom" />
                </div>
                <div className="info-right">
                  <h5 className="font-lg-bold color-gray-100">Gift voucher</h5>
                  <p className="font-sm color-gray-500">Refer a friend</p>
                </div>
              </div>
            </li>
            <li>
              <div className="item-list">
                <div className="icon-left">
                  <img src={returned} alt="Ecom" />
                </div>
                <div className="info-right">
                  <h5 className="font-lg-bold color-gray-100">
                    Return &amp; Refund
                  </h5>
                  <p className="font-sm color-gray-500">
                    Free return over ₹200
                  </p>
                </div>
              </div>
            </li>
            <li>
              <div className="item-list">
                <div className="icon-left">
                  <img src={secure} alt="Ecom" />
                </div>
                <div className="info-right">
                  <h5 className="font-lg-bold color-gray-100">
                    Secure payment
                  </h5>
                  <p className="font-sm color-gray-500">100% Protected</p>
                </div>
              </div>
            </li>
          </ul>
        </Container>
      </section>
      <section className="section-box box-newsletter">
        <Container>
          <Row>
            <Col lg={6} md={7} sm={12}>
              <h3 className="color-white">
                Subscrible &amp; Get <span className="color-warning">10%</span>{" "}
                Discount
              </h3>
              <p className="font-lg color-white">
                Get E-mail updates about our latest shop and{" "}
                <span className="font-lg-bold">special offers.</span>
              </p>
            </Col>
            <Col lg={4} md={5} sm={12}>
              <div className="box-form-newsletter mt-15">
                <form className="form-newsletter">
                  <input
                    className="input-newsletter font-xs"
                    value=""
                    placeholder="Your email Address"
                  />
                  <button className="btn btn-brand-2">Sign Up</button>
                </form>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
      <div
        className="modal fade"
        id="ModalQuickview"
        tabindex="-1"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-xl">
          <div className="modal-content apply-job-form">
            <button
              className="btn-close"
              type="button"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
            <div className="modal-body p-30">
              <Row>
                <Col lg={6}>
                  <div className="gallery-image">
                    <div className="galleries-2">
                      <div className="detail-gallery">
                        <div className="product-image-slider-2">
                          <figure className="border-radius-10">
                            <img
                              src="assets/imgs/page/product/img-gallery-1.jpg"
                              alt="product image"
                            />
                          </figure>
                          <figure className="border-radius-10">
                            <img
                              src="assets/imgs/page/product/img-gallery-2.jpg"
                              alt="product image"
                            />
                          </figure>
                          <figure className="border-radius-10">
                            <img
                              src="assets/imgs/page/product/img-gallery-3.jpg"
                              alt="product image"
                            />
                          </figure>
                          <figure className="border-radius-10">
                            <img
                              src="assets/imgs/page/product/img-gallery-4.jpg"
                              alt="product image"
                            />
                          </figure>
                          <figure className="border-radius-10">
                            <img
                              src="assets/imgs/page/product/img-gallery-5.jpg"
                              alt="product image"
                            />
                          </figure>
                          <figure className="border-radius-10">
                            <img
                              src="assets/imgs/page/product/img-gallery-6.jpg"
                              alt="product image"
                            />
                          </figure>
                          <figure className="border-radius-10">
                            <img
                              src="assets/imgs/page/product/img-gallery-7.jpg"
                              alt="product image"
                            />
                          </figure>
                        </div>
                      </div>
                      <div className="slider-nav-thumbnails-2">
                        <div>
                          <div className="item-thumb">
                            <img
                              src="assets/imgs/page/product/img-gallery-1.jpg"
                              alt="product image"
                            />
                          </div>
                        </div>
                        <div>
                          <div className="item-thumb">
                            <img
                              src="assets/imgs/page/product/img-gallery-2.jpg"
                              alt="product image"
                            />
                          </div>
                        </div>
                        <div>
                          <div className="item-thumb">
                            <img
                              src="assets/imgs/page/product/img-gallery-3.jpg"
                              alt="product image"
                            />
                          </div>
                        </div>
                        <div>
                          <div className="item-thumb">
                            <img
                              src="assets/imgs/page/product/img-gallery-4.jpg"
                              alt="product image"
                            />
                          </div>
                        </div>
                        <div>
                          <div className="item-thumb">
                            <img
                              src="assets/imgs/page/product/img-gallery-5.jpg"
                              alt="product image"
                            />
                          </div>
                        </div>
                        <div>
                          <div className="item-thumb">
                            <img
                              src="assets/imgs/page/product/img-gallery-6.jpg"
                              alt="product image"
                            />
                          </div>
                        </div>
                        <div>
                          <div className="item-thumb">
                            <img
                              src="assets/imgs/page/product/img-gallery-7.jpg"
                              alt="product image"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="box-tags">
                    <div className="d-inline-block mr-25">
                      <span className="font-sm font-medium color-gray-900">
                        Category:
                      </span>
                      <a className="link" href="#">
                        Smartphones
                      </a>
                    </div>
                    <div className="d-inline-block">
                      <span className="font-sm font-medium color-gray-900">
                        Tags:
                      </span>
                      <a className="link" href="#">
                        Blue
                      </a>
                      ,
                      <a className="link" href="#">
                        Smartphone
                      </a>
                    </div>
                  </div>
                </Col>
                <Col lg={6}>
                  <div className="product-info">
                    <h5 className="mb-15">
                      SAMSUNG Galaxy S22 Ultra, 8K Camera & Video, Brightest
                      Display Screen, S Pen Pro
                    </h5>
                    <div className="info-by">
                      <span className="bytext color-gray-500 font-xs font-medium">
                        by
                      </span>
                      <a
                        className="byAUthor color-gray-900 font-xs font-medium"
                        href="shop-vendor-list.html"
                      >
                        {" "}
                        Ecom Tech
                      </a>
                      <div className="rating d-inline-block">
                        <img src={star} alt="Ecom" />
                        <img src={star} alt="Ecom" />
                        <img src={star} alt="Ecom" />
                        <img src={star} alt="Ecom" />
                        <img src={star} alt="Ecom" />
                        <span className="font-xs color-gray-500 font-medium">
                          {" "}
                          (65 reviews)
                        </span>
                      </div>
                    </div>
                    <div className="border-bottom pt-10 mb-20"></div>
                    <div className="box-product-price">
                      <h3 className="color-brand-3 price-main d-inline-block mr-10">
                        ₹2856.3
                      </h3>
                      <span className="color-gray-500 price-line font-xl line-througt">
                        ₹3225.6
                      </span>
                    </div>
                    <div className="product-description mt-10 color-gray-900">
                      <ul className="list-dot">
                        <li>8k super steady video</li>
                        <li>Nightography plus portait mode</li>
                        <li>50mp photo resolution plus bright display</li>
                        <li>Adaptive color contrast</li>
                        <li>premium design & craftmanship</li>
                        <li>Long lasting battery plus fast charging</li>
                      </ul>
                    </div>
                    <div className="box-product-color mt-10">
                      <p className="font-sm color-gray-900">
                        Color:
                        <span className="color-brand-2 nameColor">
                          Pink Gold
                        </span>
                      </p>
                      <ul className="list-colors">
                        <li className="disabled">
                          <img src="/" alt="Ecom" title="Pink" />
                        </li>
                        <li>
                          <img
                            src="assets/imgs/page/product/img-gallery-2.jpg"
                            alt="Ecom"
                            title="Gold"
                          />
                        </li>
                        <li>
                          <img
                            src="assets/imgs/page/product/img-gallery-3.jpg"
                            alt="Ecom"
                            title="Pink Gold"
                          />
                        </li>
                        <li>
                          <img
                            src="assets/imgs/page/product/img-gallery-4.jpg"
                            alt="Ecom"
                            title="Silver"
                          />
                        </li>
                        <li className="active">
                          <img
                            src="assets/imgs/page/product/img-gallery-5.jpg"
                            alt="Ecom"
                            title="Pink Gold"
                          />
                        </li>
                        <li className="disabled">
                          <img
                            src="assets/imgs/page/product/img-gallery-6.jpg"
                            alt="Ecom"
                            title="Black"
                          />
                        </li>
                        <li className="disabled">
                          <img
                            src="assets/imgs/page/product/img-gallery-7.jpg"
                            alt="Ecom"
                            title="Red"
                          />
                        </li>
                      </ul>
                    </div>
                    <div className="box-product-style-size mt-10">
                      <Row>
                        <Col lg={12} className="mb-10">
                          <p className="font-sm color-gray-900">
                            Style:
                            <span className="color-brand-2 nameStyle">S22</span>
                          </p>
                          <ul className="list-styles">
                            <li className="disabled" title="S22 Ultra">
                              S22 Ultra
                            </li>
                            <li className="active" title="S22">
                              S22
                            </li>
                            <li title="S22 + Standing Cover">
                              S22 + Standing Cover
                            </li>
                          </ul>
                        </Col>
                        <Col lg={12} className="mb-10">
                          <p className="font-sm color-gray-900">
                            Size:
                            <span className="color-brand-2 nameSize">
                              512GB
                            </span>
                          </p>
                          <ul className="list-sizes">
                            <li className="disabled" title="1GB">
                              1GB
                            </li>
                            <li className="active" title="512 GB">
                              512 GB
                            </li>
                            <li title="256 GB">256 GB</li>
                            <li title="128 GB">128 GB</li>
                            <li className="disabled" title="64GB">
                              64GB
                            </li>
                          </ul>
                        </Col>
                      </Row>
                    </div>
                    <div className="buy-product mt-5">
                      <p className="font-sm mb-10">Quantity</p>
                      <div className="box-quantity">
                        <div className="input-quantity">
                          <input
                            className="font-xl color-brand-3"
                            type="text"
                            value="1"
                          />
                          <span className="minus-cart"></span>
                          <span className="plus-cart"></span>
                        </div>
                        <div className="button-buy">
                          <a className="btn btn-cart" href="shop-cart.html">
                            Add to cart
                          </a>
                          <a className="btn btn-buy" href="shop-checkout.html">
                            Buy now
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </Col>
              </Row>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}
export default Home;
