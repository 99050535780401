import { act } from "react";
import { AuthActionTypes, authInitialState } from "./auth.model";

const reducer = (state = authInitialState, action) => {
  switch (action.type) {
    case AuthActionTypes.SIGNUP_USER_REQUEST:
      return {
        ...state,
        isLoading: true,
      };

    case AuthActionTypes.SIGNUP_USER_SUCCESS:
      var { payload } = action;
      console.log(payload, "GroupSign");
      return {
        ...state,
        isLoading: false,
        groupMessage: payload?.data?.message,
      };

    case AuthActionTypes.SIGNUP_USER_FAILURE:
      var { payload } = action;
      console.log(payload,"userfailure");
      return {
        ...state,
        isLoading: false,
        groupError: payload?.response?.data?.message,
      };
    case AuthActionTypes.LOGIN_USERDATA_REQUEST:
      return {
        ...state,
        isLoading: true,
        success: false,
      };

    case AuthActionTypes.LOGIN_USERDATA_SUCCESS:
      var { payload } = action;
      localStorage.setItem("isAuthenticated", true);
      localStorage.setItem("type", payload?.data?.type);
      localStorage.setItem("authKey", payload?.data?.accessToken);
      localStorage.setItem("role", payload?.data?.roleId);
      localStorage.setItem("id", payload?.data?.id);

      return {
        ...state,
        isLoading: false,
        success: true,
        auth: payload?.data,
      };

    case AuthActionTypes.LOGIN_USERDATA_FAILURE:
      var { payload } = action;
      console.log(payload.response?.data?.message,);
      return {
        ...state,
        isLoading: false,
        success: false,
        error: payload?.response?.data?.message,
      };
    case AuthActionTypes.CLEAR_MESSAGES:
      var { payload } = action;
      return {
        ...state,
        groupMessage: "",
        groupError: "",
        isLoading: false,
        success: false,
        error:''
      };

    default:
      return state;
  }
};

export { reducer as AuthReducer };
