/* External Imports */
import { Col, Container, Row, Modal, Button } from "react-bootstrap";
import { Swiper as SwiperComponent } from "swiper/react";
import { SwiperSlide } from "swiper/react";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
/* Internal Imports */
import star from "../../assets/imgs/template/icons/star.svg";
import Header from "../Header";
import Footer from "../Footer";
import Sidebar from "../Sidebar";
import banner from "../../assets/imgs/page/shop/grid-2/banner.png";
import catimg1 from "../../assets/imgs/page/homepage2/cat-img-1.png";
import catimg2 from "../../assets/imgs/page/homepage2/cat-img-2.png";
import catimg3 from "../../assets/imgs/page/homepage2/cat-img-3.png";
import catimg6 from "../../assets/imgs/page/homepage2/cat-img-6.png";
import catimg7 from "../../assets/imgs/page/homepage2/cat-img-7.png";
import catimg8 from "../../assets/imgs/page/homepage2/cat-img-8.png";
import catimg9 from "../../assets/imgs/page/homepage2/cat-img-9.png";
import catimg10 from "../../assets/imgs/page/homepage2/cat-img-10.png";
import catimg16 from "../../assets/imgs/page/homepage2/cat-img-16.png";
import imgsp1 from "../../assets/imgs/page/homepage1/imgsp1.png";
import imgsp2 from "../../assets/imgs/page/homepage1/imgsp2.png";
import imgsp3 from "../../assets/imgs/page/homepage1/imgsp3.png";
import imgsp4 from "../../assets/imgs/page/homepage1/imgsp4.png";
import imgsp5 from "../../assets/imgs/page/homepage1/imgsp5.png";
import imgsp6 from "../../assets/imgs/page/homepage1/imgsp6.png";
import imgsp7 from "../../assets/imgs/page/homepage1/imgsp7.png";
import camera from "../../assets/imgs/page/homepage2/camera.png";
import clock from "../../assets/imgs/page/homepage2/clock.png";
import airpod from "../../assets/imgs/page/homepage2/airpod.png";
import delivery from "../../assets/imgs/template/delivery.svg";
import support from "../../assets/imgs/template/support.svg";
import voucher from "../../assets/imgs/template/voucher.svg";
import returned from "../../assets/imgs/template/return.svg";
import secure from "../../assets/imgs/template/secure.svg";
import stargray from "../../assets/imgs/template/icons/star-gray.svg";
import noImg from "../../assets/imgs/no-image.jpg";
import {
  BrandDataGetRequest,
  prodCategoryGetRequest,
  productDataEmailPostRequest,
  productGetRequest,
} from "../../store/products/products.action";
import { productSelector } from "../../store/products/products.model";
import { API_BASE } from "../../services/config";
import { cartSelector } from "../../store/cart/cart.model";
import {
  cartGetRequest,
  postAddToCardRequest,
  resetInitialstate,
} from "../../store/cart/cart.action";
import { useLocation, useParams, Link } from "react-router-dom";
import { createCartPayload, createProductPayload } from "../../utils/utils";
import { TaxPercentage } from "../../utils/Constants";
import ImageSlider from "../Slider/ImageSlider";

function Shoplist() {
  const { id } = useParams();
  const dispatch = useDispatch();
  const { getProducts, mailSendSuccess, getCategoryList, getBrandList } =
    useSelector(productSelector);
  const { succesMessage } = useSelector(cartSelector);
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const searchQuery = queryParams.get("query") || "";

  // State
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(30);
  const [selectedBrandIds, setSelectedBrandIds] = useState([]);
  const [selectedCategoryIds, setSelectedCategoryIds] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [order, setOrder] = useState("asc");
  const [sortBy, setSortBy] = useState("customer_name");
  const [quantity, setQuantity] = useState(0);
  const [checkboxes, setCheckboxes] = useState(
    getBrandList.reduce((acc, option) => {
      acc[option.id] = false;
      return acc;
    }, {})
  );
  const [checkboxes1, setCheckboxes1] = useState(
    getCategoryList.reduce((acc, option) => {
      acc[option.id] = false;
      return acc;
    }, {})
  );
  const [searchBrand, setSearchBrand] = useState("");
  const [searchCatg, setSearchCatg] = useState("");
  const [categorySuggestion, setCategorySuggestion] = useState([]);
  const [brandSuggestion, setBrandSuggestion] = useState([]);

  const startIndex = (page - 1) * rowsPerPage;
  const isAuthenticated = localStorage.getItem("isAuthenticated");
  const isType = localStorage.getItem("type");
  let endIndex = [page] * rowsPerPage;
  if (endIndex > getProducts?.count) {
    endIndex = getProducts?.count;
  }

  const sortByKey = (key) => {
    // const newOrder = order === "asc" ? "desc" : "asc";

    const payload = createProductPayload(
      page,
      rowsPerPage,
      searchQuery,
      "",
      "",
      key,
      order
    );

    // setOrder(newOrder);
    setSortBy(key);

    console.log(payload, "pay12324");
    dispatch(productGetRequest(payload));
  };
  const numbers = [30, 50, 100];
  const listItems = numbers.map((number) => (
    <li
      key={number.toString()}
      onClick={() => {
        setRowsPerPage(number);
        setPage(1);
      }}
      className="dropdown-item"
    >
      {number}
    </li>
  ));
  console.log(rowsPerPage, "listItems");
  const totalPages1 = Math.ceil(getProducts?.count / rowsPerPage); // Assuming totalItems is defined
  const currentPage = page; // Replace with your actual current page state
  const prevPage = currentPage > 1 ? currentPage - 1 : null;
  const nextPage1 = currentPage < totalPages1 ? currentPage + 1 < totalPages1 ?currentPage + 1:null : null;
  const nextPage2 =
    totalPages1 != currentPage
      ? currentPage < totalPages1
        ? currentPage + 2 >totalPages1 ?null:currentPage + 2
        : null
      : null;
  const lastPage_before = totalPages1 != currentPage ? "..." : null;
  const lastPage = totalPages1 != currentPage ? totalPages1 : null;

  const pagesToDisplay = [
    prevPage,
    currentPage,
    nextPage1,
    nextPage2,
    lastPage_before,
    lastPage,
  ].filter((page) => page !== null);
  const [showAll, setShowAll] = useState(false); // State to track visibility

  console.log(pagesToDisplay,'fjdafjj');
  
  const handleSeeMore = () => {
    setShowAll(!showAll); // Toggle visibility
  };
  const [showAllBrands, setShowAllBrands] = useState(false); // State to track visibility

  const handleSeeMoreBrands = () => {
    setShowAllBrands(!showAllBrands); // Toggle visibility
  };
  const totalPages = Math.ceil(getProducts?.count / rowsPerPage);

  const handlePurchaseClick = (product) => {
    setSelectedProduct(product);
    setShowModal(true);
  };

  const handleCheckboxChange = (isChecked, id) => {
    const updatedBrandIds = isChecked
      ? [...selectedBrandIds, id]
      : selectedBrandIds.filter((brandId) => brandId !== id);

    setSelectedBrandIds(updatedBrandIds);
    setCheckboxes((prevCheckboxes) => ({
      ...prevCheckboxes,
      [id]: isChecked,
    }));
  };
  const handleCategoryCheckboxChange = (isChecked, id) => {
    const updatedCategoryIds = isChecked
      ? [...selectedCategoryIds, id]
      : selectedCategoryIds.filter((catId) => catId !== id);

    setSelectedCategoryIds(updatedCategoryIds);
    setCheckboxes1((prevCheckboxes) => ({
      ...prevCheckboxes,
      [id]: isChecked,
    }));
  };

  const onPageChange = (newPage) => {
    if (newPage >= 1 && newPage <= totalPages) {
      setPage(newPage);
    }
  };

  const handleApplyFilter = () => {
    const payload = createProductPayload(
      page,
      rowsPerPage,
      "",
      selectedCategoryIds,
      selectedBrandIds.join(",")
    );
    console.log(payload, "pay12324");
    dispatch(productGetRequest(payload));
  };
  const claerFilter = () => {
    // debugger
    setSelectedBrandIds([]);
    setSelectedCategoryIds([]);
    setCheckboxes((prevCheckboxes) =>
      Object.fromEntries(Object.keys(prevCheckboxes).map((key) => [key, false]))
    );
    setCheckboxes1((prevCheckboxes) =>
      Object.fromEntries(Object.keys(prevCheckboxes).map((key) => [key, false]))
    );
    const payload = createProductPayload(page, rowsPerPage, "", "", "");
    dispatch(productGetRequest(payload));
  };
  const handleCloseModal = () => {
    setShowModal(false);
    setSelectedProduct(null);
    setQuantity("");
  };

  const handleSubmit = () => {
    // console.log((TaxPercentage.find((t) => {if(selectedProduct?.tax == t.value){return t.percent}})).percent,'ituytuyj')

    // const price= isType == 0
    //       ? (
    //           Number(selectedProduct?.msrp) +
    //           Number((TaxPercentage.find((t) => {if(selectedProduct?.tax == t.value){return t.percent}}) ).percent/ 100) * selectedProduct?.msrp
    //         ).toFixed(2)
    //       : isType == 1
    //       ? (
    //           Number(selectedProduct?.w_rate) +
    //           Number((TaxPercentage.find((t) => {if(selectedProduct?.tax == t.value){return t.percent}})).percent/ 100) * selectedProduct?.w_rate
    //         ).toFixed(2)
    //       : (
    //           Number(selectedProduct?.c_rate) +
    //           Number(((TaxPercentage.find((t) => {if(selectedProduct?.tax == t.value){return t.percent}}) )).percent/ 100) * selectedProduct?.c_rate
    //         ).toFixed(2)
    // const cartPayload={
    //   product_id: selectedProduct.id,
    //   quantity:quantity,
    //   category_id: selectedProduct.catgory_id,
    //   reg_price: selectedProduct.msrp,
    //   sale_price: selectedProduct.w_rate,
    // }

    const addData = createCartPayload(selectedProduct, quantity);
    dispatch(postAddToCardRequest({ payload: addData }));
    handleCloseModal();
  };

  const initializeData = () => {
    const payload = createProductPayload(page, rowsPerPage);
    console.log(payload, "pay1232");
    dispatch(productGetRequest(payload));
    dispatch(prodCategoryGetRequest());
    dispatch(BrandDataGetRequest());
  };

  /* useEffect */
  useEffect(() => {
    if (id && id !== "0") {
      const payload = createProductPayload(page, rowsPerPage, "", parseInt(id));
      console.log(payload, "pay12324");
      dispatch(productGetRequest(payload));
    } else {
      initializeData();
    }
  }, [id, page, rowsPerPage]);

  useEffect(() => {
    if (searchQuery) {
      const payload = createProductPayload(page, rowsPerPage, searchQuery);
      console.log(payload, "pay12324");
      dispatch(productGetRequest(payload));
    }
  }, [searchQuery]);

  useEffect(() => {
    if (succesMessage === "Product added Successfully") {
      toast.dismiss();
      toast.success("Product Added To Cart");
      dispatch(resetInitialstate());
      initializeData();
      const cartPayload = createProductPayload(0, 0);
      dispatch(cartGetRequest(cartPayload));
    }
  }, [succesMessage]);

  const handleSearchcategory = (event) => {
    const input = event.target.value;
    setSearchCatg(input);
    if (input) {
      // const payload = createProductPayload("", "", input, "");
      // dispatch(productGetRequest(payload));

      // Filter suggestions case-insensitively
      const filteredSuggestions = getCategoryList?.filter((ele) =>
        ele.name.toLowerCase().includes(input.toLowerCase())
      );
      setCategorySuggestion(filteredSuggestions);
    } else {
      setCategorySuggestion(getCategoryList); // Clear suggestions when input is empty
    }
  };
  const handleSearchbrand = (event) => {
    const input = event.target.value;
    // setSearchCatg(input);
    if (input) {
      // const payload = createProductPayload("", "", input, "");
      // dispatch(productGetRequest(payload));

      // Filter suggestions case-insensitively
      const filteredSuggestions = getBrandList?.filter((ele) =>
        ele.name.toLowerCase().includes(input.toLowerCase())
      );
      setBrandSuggestion(filteredSuggestions);
    } else {
      setBrandSuggestion(getBrandList); // Clear suggestions when input is empty
    }
  };

  useEffect(() => {
    if (getCategoryList) {
      setCategorySuggestion(getCategoryList);
    }
  }, [getCategoryList]);
  useEffect(() => {
    if (getBrandList) {
      setBrandSuggestion(getBrandList);
    }
  }, [getBrandList]);
  return (
    <>
      <Header />
      <Sidebar />
      <div className="section-box">
        <div className="breadcrumbs-div">
          <Container>
            <ul className="breadcrumb">
              <li>
                <Link to="/" className="font-xs color-gray-1000">
                  <span>Home</span>
                </Link>
              </li>
             
              <li>
                <Link to="/purchase" className="font-xs color-gray-500">
                  Purchase
                </Link>
              </li>
            </ul>
          </Container>
        </div>
      </div>

      <section className="section-box shop-template mt-30">
        <Container>
          {/* <Row>
            <Col lg={3}>
              <div className="sidebar-ads">
                <div className="bg-electronic">
                  <span className="big-deal mb-5">Big deal</span>
                  <h4 className="font-25">Electronic</h4>
                  <p className="font-16 color-brand-3">
                    Hot devices, Latest trending
                  </p>
                </div>
              </div>
            </Col>
            <Col lg={9}>
              <div className="banner-top-gray-100">
                <div className="banner-ads-top mb-30">
                  <a href="#">
                    <img src={banner} alt="Ecom" />
                  </a>
                </div>
              </div>
            </Col>
          </Row> */}
          <Row className="mobile-reverse">
            <Col lg={9} className="order-first order-lg-last">
              <div className="box-filters mt-0 pb-5 border-bottom">
                <Row>
                  <Col
                    xl={2}
                    lg={3}
                    className="mb-10 text-lg-start text-center"
                  >
                    <a
                      className="btn btn-filter font-sm color-brand-3 font-medium  d-none"
                      href="#ModalFiltersForm"
                      data-bs-toggle="modal"
                    >
                      All Filters
                    </a>

                    <Button
                      className="btn btn-brand-2"
                      size="sm"
                      onClick={claerFilter}
                    >
                      Clear Filters
                    </Button>
                  </Col>
                  <Col xl={10} lg={9} className="mb-10 text-lg-end text-center">
                    <span className="font-sm color-gray-900 font-medium border-1-right span">
                      Showing {startIndex}&ndash;{endIndex} of{" "}
                      {getProducts?.count} results
                    </span>
                    <div className="d-inline-block">
                      <span className="font-sm color-gray-500 font-medium">
                        Sort by:
                      </span>
                      <div className="dropdown dropdown-sort border-1-right">
                        <button
                          className="btn dropdown-toggle font-sm color-gray-900 font-medium"
                          id="dropdownSort"
                          type="button"
                          data-bs-toggle="dropdown"
                          aria-expanded="false"
                        >
                          products
                        </button>
                        <ul
                          className="dropdown-menu dropdown-menu-light"
                          aria-labelledby="dropdownSort"
                        >
                          <li
                            onClick={(e) => {
                              sortByKey("created_at");
                              setOrder("asc")
                            }}
                          >
                            <a className="dropdown-item ">
                              Latest products
                            </a>
                          </li>
                          <li
                            onClick={(e) => {
                              sortByKey("created_at");
                              setOrder("desc")
                            }}
                          >
                            <a className="dropdown-item">Oldest products</a>
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div className="d-inline-block">
                      <span className="font-sm color-gray-500 font-medium">
                        Show
                      </span>
                      <div className="dropdown dropdown-sort border-1-right">
                        <button
                          className="btn dropdown-toggle font-sm color-gray-900 font-medium"
                          id="dropdownSort2"
                          type="button"
                          data-bs-toggle="dropdown"
                          aria-expanded="false"
                          data-bs-display="static"
                        >
                          <span>{rowsPerPage} items</span>
                        </button>
                        <ul
                          className="dropdown-menu dropdown-menu-light"
                          aria-labelledby="dropdownSort2"
                        >
                          {listItems}
                          {/* <li
                            value="30"
                            onClick={(e) => limits(e.target.value)}
                          >
                            <a className="dropdown-item active" href="#">
                              30 items
                            </a>
                          </li>
                          <li
                            value="50"
                            onClick={(e) => limits(e.target.value)}
                          >
                            <a className="dropdown-item" href="#">
                              50 items
                            </a>
                          </li>
                          <li
                            value="100"
                            onClick={(e) => limits(e.target.value)}
                          >
                            <a className="dropdown-item" href="#">
                              100 items
                            </a>
                          </li> */}
                        </ul>
                      </div>
                    </div>
                    {/* <div className="d-inline-block">
                      <a
                        className="view-type-grid mr-5 active"
                        href="shop-grid.html"
                      ></a>
                      <a className="view-type-list" href="shop-list.html"></a>
                    </div> */}
                  </Col>
                </Row>
              </div>
              <Row className="mt-20">
                {console.log(getProducts, "hfhd")}
                {getProducts?.data?.map((ele, idx) => {
                  const tax = TaxPercentage.find((t) => ele?.tax == t.value);
                  const price =tax?.percent !=0?
                    isType == 0
                      ? (
                          Number(ele?.msrp) +
                          (tax?.percent / 100) * ele?.msrp
                        ).toFixed(2)
                      : isType == 1
                      ? (
                          Number(ele?.w_rate) +
                          (tax?.percent / 100) * ele?.w_rate
                        ).toFixed(2)
                      : (
                          Number(ele?.c_rate) +
                          (tax?.percent / 100) * ele?.c_rate
                        ).toFixed(2):
                        isType==0?ele?.msrp:isType==1?ele?.w_rate: ele?.c_rate;

                  const maxPrice = (
                    Number(ele?.msrp) +
                    (tax?.percent / 100) * ele?.msrp
                  ).toFixed(2);
                  console.log(price,tax?.percent , ele?.c_rate,isType,'ldjflj');
                  
                  return (
                    <Col lg={3}>
                      <div className="card-grid-style-3">
                        <div className="card-grid-inner">
                          <div className="image-box">
                            {ele?.w_rate != ele?.msrp && (
                              <span className="label bg-brand-2">
                                {(100 - (ele?.w_rate / ele.msrp) * 100).toFixed(
                                  0
                                )}
                                %
                              </span>
                            )}
                            {/* <img
                              src={
                                ele?.image
                                  ? API_BASE + "" + ele?.image
                                  : noImg
                              }
                              alt="Ecom"
                            /> */}

                            <ImageSlider images={ele.image} id={idx} />
                          </div>
                          <div className="info-right">
                            <span className="font-xs color-gray-500">
                              {ele?.category?.name}
                            </span>
                            <span className="font-xs f-right color-gray-500">
                              {ele?.brand?.name}
                            </span>
                            <br />

                            <h4 className="color-brand-3 font-sm-bold">
                              {ele?.name}
                            </h4>

                            {isAuthenticated && (
                              <div className="price-info">
                                <strong className="font-lg-bold color-brand-3 price-main">
                                  ₹ {isNaN(price) ? "0" : price}
                                </strong> <br />
                                {price < maxPrice && (
                                  <span className="color-gray-500 price-line">
                                  {}₹ {isNaN(maxPrice) ? "0" : maxPrice}
                                  </span>
                                )}
                              </div>
                            )}

                            <ul className="list-features">
                              {/* <li> {ele?.name}</li> */}
                              {/* <li>3.1GHz 6-core 10th-generation Intel Core i5</li>
                            <li>AMD Radeon Pro 5300 graphics</li> */}
                            </ul>
                            {isAuthenticated && (
                              <div className="mt-20">
                                <button
                                  className="btn btn-cart"
                                  onClick={() => handlePurchaseClick(ele)}
                                  // disabled={ele?.product_inventory[0]?.total_qty>0?false:true}
                                >
                                  Purchase
                                  {/* {ele?.product_inventory[0]?.total_qty>0?"Purchase": "Out Of Stock"} */}
                                </button>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </Col>
                  );
                })}
              </Row>

              <nav>
                <ul className="pagination">
                  <li className={`page-item a ${page === 1 ? "disabled" : ""}`}>
                    <a
                      className="page-link page-prev"
                      onClick={() => onPageChange(1)}
                    >
                      First
                    </a>
                  </li>
                  <li className={`page-item a ${page === 1 ? "disabled" : ""}`}>
                    <a
                      className="page-link page-prev"
                      onClick={() => onPageChange(page - 1)}
                    >
                      Prev
                    </a>
                  </li>

                  {pagesToDisplay.map((page) => {
                    return page != "..." ? (
                      <li
                        key={page}
                        className={`page-item b ${
                          currentPage === page ? "active" : ""
                        }`}
                      >
                        <a
                          className="page-link"
                          onClick={() => onPageChange(page)}
                        >
                          {page}
                        </a>
                      </li>
                    ) : page == "..." ? (
                      <li
                        key={page}
                        className={`page-item ${
                          currentPage === page ? "active" : ""
                        }`}
                      >
                        <a className="page-link">{page}</a>
                      </li>
                    ) : (
                      ""
                    );
                  })}

                  <li
                    className={`page-item ${
                      page === totalPages ? "disabled" : ""
                    }`}
                  >
                    <a
                      className="page-link page-next"
                      onClick={() => onPageChange(page + 1)}
                    >
                      Next
                    </a>
                  </li>
                  <li
                    className={`page-item ${
                      page === totalPages ? "disabled" : ""
                    }`}
                  >
                    <a
                      className="page-link page-next"
                      onClick={() => onPageChange(totalPages)}
                    >
                      Last
                    </a>
                  </li>
                </ul>
              </nav>
            </Col>
            <Col lg={3} className="order-last order-lg-first mobile-mb-40">
              <div className="sidebar-border mb-0">
                <div className="sidebar-head">
                  <h6 className="color-gray-900 mb-10">Product Categories</h6>

                  <form className="form-search">
                    <div className="box-keysearch">
                      <input
                        className="form-control font-xs"
                        type="text"
                        // value={searchQuery}
                        placeholder="Search for category"
                        onChange={handleSearchcategory}
                      />
                    </div>
                  </form>
                </div>
                <div className="sidebar-content">
                  <ul className="list-nav-arrow">
                    {categorySuggestion?.map((ele, idx) => {
                      if (idx <= 4) {
                        return (
                          <li key={ele?.id}>
                            <label className="cb-container">
                              <input
                                type="checkbox"
                                checked={checkboxes1[ele.id]}
                                onChange={(e) =>
                                  handleCategoryCheckboxChange(
                                    e.target.checked,
                                    ele.id
                                  )
                                }
                              />
                              <span className="text-small">{ele?.name}</span>
                              <span className="checkmark"></span>
                            </label>
                          </li>
                        );
                      }
                    })}
                  </ul>
                  {categorySuggestion.length > 5 && ( // Only show if there are more than 5 items
                    <a
                      className="link-see-more mt-5"
                      data-bs-toggle="collapse"
                      role="button"
                      aria-expanded="false"
                      aria-controls="moreMenu"
                      onClick={handleSeeMore}
                    >
                      {showAll ? "See Less" : "See More"}
                    </a>
                  )}
                  <ul className="list-nav-arrow">
                    {getCategoryList?.map((ele, idx) => {
                      if (idx > 4 && showAll) {
                        return (
                          <li key={ele?.id}>
                            <label className="cb-container">
                              <input
                                type="checkbox"
                                checked={checkboxes1[ele.id]}
                                onChange={(e) =>
                                  handleCategoryCheckboxChange(
                                    e.target.checked,
                                    ele.id
                                  )
                                }
                              />
                              <span className="text-small">{ele?.name}</span>
                              <span className="checkmark"></span>
                            </label>
                          </li>
                        );
                      }
                    })}
                  </ul>
                </div>
              </div>
              <div className="sidebar-border mb-0">
                <div className="sidebar-head">
                  <h6 className="color-gray-900 mb-10">Product Brands</h6>

                  <form className="form-search">
                    <div className="box-keysearch">
                      <input
                        className="form-control font-xs"
                        type="text"
                        // value={searchQuery}
                        placeholder="Search for brand"
                        onChange={handleSearchbrand}
                      />
                    </div>
                  </form>
                </div>
                <div className="sidebar-content">
                  {/* <h6 className="color-gray-900 mt-10 mb-10">Price</h6> */}
                  {/* <div className="box-slider-range mt-20 mb-15"></div> */}

                  {/* <h6 className="color-gray-900 mt-20 mb-10">Brands</h6> */}
                  <ul className="list-checkbox">
                    {brandSuggestion?.map((ele, idx) => {
                      if (idx <= 4) {
                        return (
                          <li key={ele?.id}>
                            <label className="cb-container">
                              <input
                                type="checkbox"
                                checked={checkboxes[ele.id]}
                                onChange={(e) =>
                                  handleCheckboxChange(e.target.checked, ele.id)
                                }
                              />
                              <span className="text-small">{ele?.name}</span>
                              <span className="checkmark"></span>
                            </label>
                            {/* <span className="number-item">{idx + 1}</span> */}
                          </li>
                        );
                      }
                    })}
                    {brandSuggestion.length > 5 && ( // Only show if there are more than 5 items
                      <a
                        className="link-see-more mt-5"
                        data-bs-toggle="collapse"
                        role="button"
                        aria-expanded="false"
                        aria-controls="moreMenu"
                        onClick={handleSeeMoreBrands}
                      >
                        {showAllBrands ? "See Less" : "See More"}
                      </a>
                    )}
                    {getBrandList?.map((ele, idx) => {
                      if (idx > 4 && showAllBrands) {
                        return (
                          <li key={ele?.id}>
                            <label className="cb-container">
                              <input
                                type="checkbox"
                                checked={checkboxes[ele.id]}
                                onChange={(e) =>
                                  handleCheckboxChange(e.target.checked, ele.id)
                                }
                              />
                              <span className="text-small">{ele?.name}</span>
                              <span className="checkmark"></span>
                            </label>
                            {/* <span className="number-item">{idx + 1}</span> */}
                          </li>
                        );
                      }
                    })}
                  </ul>
                  <button
                    className="btn btn-brand-2"
                    onClick={() => handleApplyFilter()}
                  >
                    Apply Filter
                  </button>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
      <section className="section-box mt-90 mb-50">
        <Container>
          <ul className="list-col-5">
            <li>
              <div className="item-list">
                <div className="icon-left">
                  <img src={delivery} alt="Ecom" />
                </div>
                <div className="info-right">
                  <h5 className="font-lg-bold color-gray-100">Free Delivery</h5>
                  <p className="font-sm color-gray-500">
                    From all orders over $10
                  </p>
                </div>
              </div>
            </li>
            <li>
              <div className="item-list">
                <div className="icon-left">
                  <img src={support} alt="Ecom" />
                </div>
                <div className="info-right">
                  <h5 className="font-lg-bold color-gray-100">Support 24/7</h5>
                  <p className="font-sm color-gray-500">Shop with an expert</p>
                </div>
              </div>
            </li>
            <li>
              <div className="item-list">
                <div className="icon-left">
                  <img src={voucher} alt="Ecom" />
                </div>
                <div className="info-right">
                  <h5 className="font-lg-bold color-gray-100">Gift voucher</h5>
                  <p className="font-sm color-gray-500">Refer a friend</p>
                </div>
              </div>
            </li>
            <li>
              <div className="item-list">
                <div className="icon-left">
                  <img src={returned} alt="Ecom" />
                </div>
                <div className="info-right">
                  <h5 className="font-lg-bold color-gray-100">
                    Return &amp; Refund
                  </h5>
                  <p className="font-sm color-gray-500">
                    Free return over $200
                  </p>
                </div>
              </div>
            </li>
            <li>
              <div className="item-list">
                <div className="icon-left">
                  <img src={secure} alt="Ecom" />
                </div>
                <div className="info-right">
                  <h5 className="font-lg-bold color-gray-100">
                    Secure payment
                  </h5>
                  <p className="font-sm color-gray-500">100% Protected</p>
                </div>
              </div>
            </li>
          </ul>
        </Container>
      </section>
      <section className="section-box box-newsletter">
        <Container>
          <Row>
            <Col lg={6} md={7} sm={12}>
              <h3 className="color-white">
                Subscrible &amp; Get <span className="color-warning">10%</span>{" "}
                Discount
              </h3>
              <p className="font-lg color-white">
                Get E-mail updates about our latest shop and{" "}
                <span className="font-lg-bold">special offers.</span>
              </p>
            </Col>
            <Col lg={4} md={5} sm={12}>
              <div className="box-form-newsletter mt-15">
                <form className="form-newsletter">
                  <input
                    className="input-newsletter font-xs"
                    value=""
                    placeholder="Your email Address"
                  />
                  <button className="btn btn-brand-2">Sign Up</button>
                </form>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
      <Footer />
      <Modal show={showModal} onHide={handleCloseModal}>
        <Modal.Header closeButton>
          <Modal.Title>Purchase Product</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {selectedProduct && (
            <>
              <h5>{selectedProduct.name}</h5>
              {/* <img
                src={
                  selectedProduct.image
                    ? API_BASE + "products/" + selectedProduct.image
                    : noImg
                }
                alt={selectedProduct.name}
                style={{ width: "100%", marginBottom: "15px" }}
              /> */} 
            
              <ImageSlider images={selectedProduct.image} id={100210} heightStyle={"300px"}/>
             
              
              <p>
                <strong>Price:</strong> ₹
                {selectedProduct?.tax!="0"?
                isType == 0
                  ? (
                      Number(selectedProduct?.msrp) +
                      Number(
                        TaxPercentage.find((t) => {
                          if (selectedProduct?.tax == t?.value) {
                            return t?.percent;
                          }
                        })?.percent / 100
                      ) *
                      Number(selectedProduct?.msrp)
                    ).toFixed(2)
                  : isType == 1
                  ? (
                      Number(selectedProduct?.w_rate) +
                      Number(
                        TaxPercentage.find((t) => {
                          if (selectedProduct?.tax == t?.value) {
                            return t?.percent;
                          }
                        })?.percent / 100
                      ) *
                      Number(selectedProduct?.w_rate)
                    ).toFixed(2)
                  : (
                      Number(selectedProduct?.c_rate) +
                      Number(
                        TaxPercentage.find((t) => {
                          if (selectedProduct?.tax == t?.value) {
                            return t?.percent;
                          }
                        })?.percent / 100
                      ) *
                      Number(selectedProduct?.c_rate)
                    ).toFixed(2)
                  :isType==0?selectedProduct?.msrp:isType==1?selectedProduct?.w_rate:selectedProduct?.c_rate}
              </p>
              <p>
                <strong>Description:</strong> {selectedProduct.name}
              </p>
              <div className="form-group">
                <label htmlFor="quantity">Enter Product Quantity:</label>
                <input
                  type="number"
                  className="form-control"
                  id="quantity"
                  value={quantity}
                  onChange={(e) => setQuantity(e.target.value)}
                  placeholder="Email Quantity"
                  required
                />
              </div>
            </>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseModal}>
            Close
          </Button>
          <Button
            variant="primary"
            onClick={handleSubmit}
            disabled={quantity <= 0}
          >
            Submit
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
export default Shoplist;
