/* External Imports */
import React, { useEffect, useState } from "react";
import { Col, Container, Form, Modal, Row } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

/* Internal Imports */
import {
  emailValidator,
  loginOtpValidator,
  signinValidator,
} from "../validators/Validators";
import Footer from "./Footer";
import Header from "./Header";
import Sidebar from "./Sidebar";
import { useDispatch, useSelector } from "react-redux";
import { clearAuthMessage, loginUserRequest } from "../store/auth/auth.action";
import { useNavigate } from "react-router-dom";
import { authSelector } from "../store/auth/auth.model";
import {
  loginStateReset,
  userLoginRequest,
  userRegisterRequest,
} from "../store/user/user.action";
import { userSelector } from "../store/user/user.model";
function Login() {
  const dispatch = useDispatch();
  const isAuth = localStorage.getItem("isAuthenticated");
  const { success, error } = useSelector(authSelector);
  const { loginSuccess, user_email, registerSuccess } =
    useSelector(userSelector);
  console.log(loginSuccess, registerSuccess, "45678");
  const navigate = useNavigate();
  const {
    register: register1,
    handleSubmit: handleSubmit1,
    formState: { errors: errors3 },
    reset: reset1,
  } = useForm({
    resolver: yupResolver(signinValidator),
  });
  const {
    register: register2,
    handleSubmit: handleSubmit2,
    formState: { errors: errors1 },
    reset: reset2,
  } = useForm({
    resolver: yupResolver(emailValidator),
  });
  console.log(errors1, "678sf");
  const {
    register: register3,
    handleSubmit: handleSubmit3,
    formState: { errors: errors2 },
    setValue: setValue3,
    reset: reset3,
  } = useForm({
    resolver: yupResolver(loginOtpValidator),
  });
  /* State */
  const [userRegisterShow, setuserRegisterShow] = useState(false);
  const [userLoginShow, setuserLoginShow] = useState(false);

  /* Functions */
  const hanldeSigin = (formData) => {
    console.log(formData, "rtfghbjn");
    dispatch(loginUserRequest(formData));
  };
  const onUserRegister = (formData) => {
    console.log(formData, "UsesrData");
    dispatch(userRegisterRequest(formData));
  };
  const onuserLogin = (formData) => {
    console.log(formData, "vlauea");
    dispatch(userLoginRequest(formData));
  };

  /* UseEffect */
  useEffect(() => {
    setValue3("email", user_email);
  }, [user_email]);
  useEffect(() => {
    if (registerSuccess) {
      setuserRegisterShow(false);
      setuserLoginShow(true);
    }
  }, [registerSuccess]);
  useEffect(() => {
    if (isAuth && loginSuccess) {
      navigate("/");
      dispatch(loginStateReset());
      setuserLoginShow(false);
      reset2();
      reset3();
    }
    if (isAuth && success) {
      navigate("/");
      reset1();
      dispatch(clearAuthMessage());
    }
  }, [isAuth, loginSuccess, success]);
  
  
  useEffect(() => {
    dispatch(clearAuthMessage());
  }, []);

  return (
    <>
      <Header />
      <Sidebar />
      <section className="section-box shop-template mt-60">
        <Container>
          <Row className="mb-100 justify-content-center">
            <Col lg={5}>
              <form
                className="tooltip-end-bottom"
                onSubmit={handleSubmit1(hanldeSigin)}
              >
                <h3>Member Login</h3>
                <p className="font-md color-gray-500">Welcome back!</p>
                <div className="form-register mt-30 mb-30">
                  <div className="form-group">
                    <label className="mb-5 font-sm color-gray-700">
                      Email *
                    </label>
                    <input
                      className="form-control"
                      type="text"
                      placeholder="Enter Your Email Id"
                      {...register1("email")}
                    />
                    {errors3?.email?.message && (
                      <div>
                        <p className="error text-danger">
                          {errors3?.email?.message}
                        </p>
                      </div>
                    )}
                  </div>
                  <div className="form-group">
                    <label className="mb-5 font-sm color-gray-700">
                      Password *
                    </label>
                    <input
                      className="form-control"
                      type="password"
                      placeholder="Enter your Password"
                      {...register1("password")}
                    />
                    {errors3?.password?.message && (
                      <div>
                        <p className="error text-danger">
                          {errors3?.password?.message}
                        </p>
                      </div>
                    )}
                    {error &&
                      (error === "Email not found" ? (
                        <p className="error text-danger text-center">
                          It looks like this email isn’t registered.
                          Double-check and try again.
                        </p>
                      ) : (
                        <p className="error text-danger text-center">
                          The password you entered is incorrect. Please try
                          again.
                        </p>
                      ))}
                  </div>

                  <Row>
                    <Col lg={6}>
                      <div className="form-group">
                        <label className="color-gray-500 font-xs">
                          <input className="checkagree" type="checkbox" />
                          Remember me
                        </label>
                      </div>
                    </Col>
                    <Col lg={6} className="text-end">
                      <div className="form-group">
                        <a className="font-xs color-gray-500" href="#">
                          Forgot your password?
                        </a>
                      </div>
                    </Col>
                  </Row>
                  <div className="form-group">
                    <button className="font-md-bold btn btn-buy" type="submit">
                      Sign In
                    </button>
                  </div>
                </div>
              </form>
              <div className="d-flex justify-content-around">
                <div>
                  <button
                    type="button"
                    className="btn btn-brand-2"
                    onClick={() => {
                      setuserRegisterShow(!userRegisterShow);
                    }}
                  >
                    Loing By Email
                  </button>
                </div>
                <div>
                  <button className="btn btn-brand-2">Loing By SMS</button>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
      <section className="section-box box-newsletter">
        <Container>
          <Row>
            <Col lg={6} md={7} sm={12}>
              <h3 className="color-white">
                Subscrible &amp; Get <span className="color-warning">10%</span>{" "}
                Discount
              </h3>
              <p className="font-lg color-white">
                Get E-mail updates about our latest shop and{" "}
                <span className="font-lg-bold">special offers.</span>
              </p>
            </Col>
            <Col lg={4} md={5} sm={12}>
              <div className="box-form-newsletter mt-15">
                <input
                  className="input-newsletter font-xs"
                  value=""
                  placeholder="Your email Address"
                />
                <button className="btn btn-brand-2">Sign Up</button>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
      <Footer />

      <Modal
        show={userRegisterShow}
        onHide={() => setuserRegisterShow(!userRegisterShow)}
      >
        <form
          className="tooltip-end-bottom"
          onSubmit={handleSubmit2(onUserRegister)}
        >
          <Modal.Header className="border-0">
            <Modal.Title className="w-100 text-center">Send OTP</Modal.Title>
            <button
              type="button"
              className="btn-close"
              onClick={() => setuserRegisterShow(!userRegisterShow)}
              aria-label="Close"
            >
              <i className="material-icons text-muted"></i>
            </button>
          </Modal.Header>
          <Modal.Body>
            <Row className="justify-content-center">
              <Col lg={12}>
                <div className="form-group">
                  <label className="label-style">Email:</label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Your Email"
                    name="email"
                    {...register2("email")}
                  />
                </div>
              </Col>
            </Row>
          </Modal.Body>

          <Modal.Footer className="justify-content-center">
            <button type="submit" className="btn btn-primary main-btn">
              Submit
            </button>
          </Modal.Footer>
        </form>
      </Modal>

      <Modal
        show={userLoginShow}
        onHide={() => setuserLoginShow(!userLoginShow)}
      >
        <form
          className="tooltip-end-bottom"
          onSubmit={handleSubmit3(onuserLogin)}
        >
          <Modal.Header className="border-0">
            <Modal.Title className="w-100 text-center"></Modal.Title>
            <button
              type="button"
              className="btn-close"
              onClick={() => setuserLoginShow(!userLoginShow)}
              aria-label="Close"
            >
              <i className="material-icons text-muted"></i>
            </button>
          </Modal.Header>
          <Modal.Title className="w-100 text-center">Get OTP</Modal.Title>

          <Modal.Body>
            <Row className="justify-content-center">
              <Col lg={6}>
                <div className="form-group">
                  <label className="label-style">Email :</label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Your Email"
                    name="email"
                    {...register3("email")}
                  />
                  <p className="text-danger"></p>
                </div>
              </Col>
              <Col lg={6}>
                <div className="form-group">
                  <label className="label-style">OTP :</label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter OTP"
                    name="otp"
                    {...register3("otp")}
                  />
                  <p className="text-danger"></p>
                </div>
              </Col>
            </Row>
          </Modal.Body>

          <Modal.Footer className="justify-content-center">
            <button type="submit" className="btn btn-primary main-btn">
              Login
            </button>
            <button type="button" className="btn btn-secondary main-btn mx-2">
              Resend
            </button>
          </Modal.Footer>
        </form>
      </Modal>
    </>
  );
}
export default Login;
